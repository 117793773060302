<template>

  <v-simple-table class="mt-5">
    <template v-slot:top >
      <v-row>
        <v-col class="m-3 d-flex">
          <a @click="$router.back()" class="mr-2" color="primary" ><v-icon color="primary"  >mdi-chevron-left</v-icon><span>{{ $t('Back') }}</span></a>
          <p>{{ $t('Relation ')}}{{name}}{{$t(' transitions history') }}</p>
        </v-col>
      </v-row>
    </template>

    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            {{$t('Type')}}
          </th>
          <th class="text-left">
            {{$t('From')}}
          </th>
          <th class="text-left">
            {{$t('To')}}
          </th>
          <th class="text-left">
            {{$t('Date')}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in relationTransitions"
          :key="item.dateTime"
        >
          <td>{{ getTransitionType(item.type) }}</td>
          <td>{{ fetchIdValue(item.type,item.from) }}</td>
          <td>{{ fetchIdValue(item.type,item.to) }}</td>
          <td>{{ new Date(item.dateTime).toLocaleString() }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
    export default {
        data(){
            return{
                id:"",
                name:"",
                relationTransitions:[],
                TransitionTypes:[],
                relStatuses:[],
                ClientTypes:[],
                users:[],
            }
        },
        created: function()
        {
            if(this.$route.query.id){
                this.id = this.$route.query.id
                this.name = this.$route.query.name
                this.fetchTransitionTypes();
                this.fetchrelStatuses()
                this.fetchUsers();
                this.fetchClientTypes();
            }
        },
        methods: {
            getTransitionType(id){
              let filterData = this.TransitionTypes.filter(function(TransitionType){
                return TransitionType.value == id
              })
              return filterData[0]!=undefined?filterData[0].name:id;
            },

            fetchIdValue(type,id){
              if(type==1){
                return this.getRelStatus(id);
              }
              if(type==2){
                return this.getClientType(id);
              }
              if(type==3){
                return this.getUser(id);
              }
            },

            getUser(userid){
              let filterUser = this.users.filter(function(user){
                return user.id == userid
              })
              return filterUser[0]!=undefined?filterUser[0].fullName:userid;
            },

            getRelStatus(id){
              if(id === ""){
                id = -1;
              }
              let filterData = this.relStatuses.filter(function(relStatus){
                return relStatus.value == id
              })
              return filterData[0]!=undefined?this.$t(filterData[0].name):'';
            },

            getClientType(id){
              let filterData = this.ClientTypes.filter(function(ClientType){
                return ClientType.id == id
              })
              return filterData[0]!=undefined?filterData[0].name:id;
            },

            fetchrelStatuses()
            {
                let uri2 = this.$urlPrefix + '/RelationStatus/GetAll';
                this.axios.get(uri2).then((response) => {
                    this.relStatuses = response.data;
                });
            },
             fetchClientTypes()
            {
                let uri = this.$urlPrefix + '/ClientType/CountAll';
                this.axios.get(uri).then((response) => {
                    let uri2 = this.$urlPrefix + '/ClientType/GetList?start=0&count='+response.data;
                    this.axios.get(uri2).then((response) => {
                        this.ClientTypes = response.data;
                    });
                });
            },
            fetchUsers()
            {
                let uri = this.$urlPrefix + '/User/CountAll?includeDeleted=true';
                this.axios.get(uri).then((response) => {
                    let uri2 = this.$urlPrefix + '/User/GetList?start=0&count='+response.data+'&includeDeleted=true';
                    this.axios.get(uri2).then((response) => {
                        this.users = response.data;
                    });
                });
            },

            fetchTransitionTypes()
            {
              let uri2 = this.$urlPrefix + '/RelationTransitionType/GetAll';
              this.axios.get(uri2).then((response) => {
                  this.TransitionTypes = response.data;
                  this.fetchRelationTransitions();
              });
            },

            fetchRelationTransitions(){
              this.relationTransitions = this.$route.query.relationTransitions;

            }

        }
    }
</script>
